import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

import { Page404, Page500, Register } from './views/Pages';

//import { DefaultLayout } from './containers';
import AuthOrApp from './authOrApp';
import QRLeitos from './views/qrcode/leitos';
import Perguntas from './views/qrcode/perguntas';
import Doacao from './views/gerencianet/doacao';
import Medicamento from './views/qrcode/medicamento';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/leitos/:id" name="Leitos" component={QRLeitos} />
          <Route exact path="/Perguntas" name="Perguntas" component={Perguntas} />
          <Route exact path="/doacao" name="Doacao" component={Doacao} />
          <Route exact path="/medicamento" name="Medicamento" component={Medicamento} />
          <Route exact path="/medicamento/:tipo/:id" name="Medicamento" component={Medicamento} />
          <Route path="/" name="Home" component={AuthOrApp} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
