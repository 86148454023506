import React, { Component } from 'react';
import If from '../../util/if'
import JanelaPadrao from '../../componentes/JanelaPadrao'
import InputMask from 'react-input-mask';
import { postJSON } from '../../util/metodos'
import Moment from 'moment'
import logo from '../../assets/img/brand/logo.svg'
import { Card, CardBody, Row } from 'reactstrap';

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = { loading: false, pago: false, url: "", tpPagamento: 1, uf: "PI", nome: "", cpf: "", tel: "", email: "", nascimento: "", rua: "", numero: "", bairro: "", cep: "", cidade: "", cartao: "visa", numeroCartao: "", cvv: "", validade: "", valor: "0" }
        //this.state = { loading: false, pago: false, url: "", tpPagamento: 1, uf: "PI", nome: "Vinicius padua", cpf: "96826266334", tel: "86994325144", email: "asd@asd.com", nascimento: "06111983", rua: "asdasd", numero: "23", bairro: "asdad", cep: "64049280", cidade: "asdasd", cartao: "visa", numeroCartao: "", cvv: "", validade: "", valor: "250" }

        this.changeUF = this.changeUF.bind(this)
        this.changeNOME = this.changeNOME.bind(this)
        this.changeNOME = this.changeNOME.bind(this)
        this.changeCPF = this.changeCPF.bind(this)
        this.changeTEL = this.changeTEL.bind(this)
        this.changeEMAIL = this.changeEMAIL.bind(this)
        this.changeNASCIMENTO = this.changeNASCIMENTO.bind(this)
        this.changeRUA = this.changeRUA.bind(this)
        this.changeNUMERO = this.changeNUMERO.bind(this)
        this.changeBAIRRO = this.changeBAIRRO.bind(this)
        this.changeCEP = this.changeCEP.bind(this)
        this.changeCIDADE = this.changeCIDADE.bind(this)
        this.changeCARTAO = this.changeCARTAO.bind(this)
        this.changeNUMEROCARTAO = this.changeNUMEROCARTAO.bind(this)
        this.changeCVV = this.changeCVV.bind(this)
        this.changeVALIDADE = this.changeVALIDADE.bind(this)
        this.changeVALOR = this.changeVALOR.bind(this)
        this.handlePGCartao = this.handlePGCartao.bind(this)
        this.handlePGBoleto = this.handlePGBoleto.bind(this)
        this.gerar = this.gerar.bind(this)
        this.retornoJS = this.retornoJS.bind(this)
    }

    replaceAll(text) {
        text = text.replace('.', '');
        text = text.replace('.', '');
        text = text.replace('.', '');
        text = text.replace('-', '');
        text = text.replace('-', '');
        text = text.replace('-', '');
        text = text.replace('(', '');
        text = text.replace(')', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('/', '');
        text = text.replace('/', '');
        text = text.replace('/', '');
        text = text.replace('/', '');
        text = text.replace('/', '');
        text = text.replace('/', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        text = text.replace('_', '');
        return text.trim();
    }

    gerar() {
        const validarCpf = require('validar-cpf')
        var validador = require("validator")

        if ((this.replaceAll(this.state.nome).length || 0) <= 5) {
            alert("Informe o nome!")
            return
        }

        const ano = this.state.nascimento.substring(6)
        const mes = this.state.nascimento.substring(3, 5)
        const dia = this.state.nascimento.substring(0, 2)

        if (validador.toDate(mes + "/" + dia + "/" + ano) === null || validador.toInt(ano) >= Moment().year()) {
            alert("Informe a data de nascimento válida!")
            return
        }

        if (!validarCpf(this.state.cpf)) {
            alert("CPF não é valido!")
            return
        }

        if ((this.replaceAll(this.state.tel).length || 0) < 10) {
            alert("Informe o telefone!")
            return
        }

        if (!validador.isEmail(this.state.email)) {
            alert("Informe um e-mail válido!")
            return
        }

        if ((this.replaceAll(this.state.rua).length || 0) <= 3) {
            alert("Informe a rua!")
            return
        }

        if ((this.replaceAll(this.state.numero).length || 0) <= 1) {
            alert("Informe o número!")
            return
        }

        if ((this.replaceAll(this.state.bairro).length || 0) <= 3) {
            alert("Informe o bairro!")
            return
        }

        if ((this.replaceAll(this.state.cep).length || 0) !== 8) {
            alert("Informe o cep!")
            return
        }

        if ((this.replaceAll(this.state.cidade).length || 0) <= 3) {
            alert("Informe a cidade!")
            return
        }

        if (this.state.tpPagamento === 1) {
            if (!validador.isCreditCard(this.state.numeroCartao)) {
                alert("Número do cartão não é válido!")
                return
            }

            if ((this.state.cvv.replace("_", "").length || 0) !== 3) {
                alert("Informe o CVV número do cartão!")
                return
            }
            /*
                        if ((this.state.validade.replace("_", "").replace("/", "").length || 0) !== 6
                            || validador.toInt(this.state.validade.substring(3)) < Moment().year()
                            || validador.toInt(this.state.validade.substring(0, 2)) > 12) {
                            alert("Validade número do cartão esta inválida!")
                            return
                        }
                        */
        }

        if (isNaN(validador.toFloat(this.state.valor)) || validador.toFloat(this.state.valor) < 25) {
            alert("Valor da doação deve ser maior que R$ 25,00")
            return
        }


        this.setState({ ...this.state, loading: true })

        if (this.state.tpPagamento === 1)
            window.gerar(this.retornoJS, this.state.cartao, this.state.numeroCartao, this.state.cvv, this.state.validade.substring(0, 2), this.state.validade.substring(3, 7));
        else
            this.retornoJS(null, null)
    }

    retornoJS(tipo, obj) {

        var sendData = {}
        sendData = {
            tpPagamento: this.state.tpPagamento,
            nome: this.state.nome,
            cpf: this.state.cpf,
            telefone: this.state.tel,
            email: this.state.email,
            birth: this.state.nascimento,
            street: this.state.rua,
            number: this.state.numero,
            neighborhood: this.state.bairro,
            zipcode: this.state.cep,
            city: this.state.cidade,
            state: this.state.uf,
            ID: "",
            vencimento: Moment().add(1, 'days'),
            descricao: "Doação",
            valor: this.state.valor
        }

        var comErro = false
        if (this.state.tpPagamento === 1) {

            if (tipo === 1) {
                sendData = {
                    paymentToken: obj.data.payment_token,
                    tpPagamento: this.state.tpPagamento,
                    nome: this.state.nome,
                    cpf: this.state.cpf,
                    telefone: this.state.tel,
                    email: this.state.email,
                    birth: this.state.nascimento,
                    street: this.state.rua,
                    number: this.state.numero,
                    neighborhood: this.state.bairro,
                    zipcode: this.state.cep,
                    city: this.state.cidade,
                    state: this.state.uf,
                    ID: "",
                    vencimento: Moment().add(1, 'days'),
                    descricao: "Doação",
                    valor: this.state.valor
                }

            } else {
                console.log(obj)
                comErro = true
                this.setState({ ...this.state, loading: false, pago: false, url: "" })

                var msgErro = ""
                if (obj["error"])
                    msgErro += obj["error"] + " "

                if (obj["error_description"])
                    msgErro += obj["error_description"].message + " "

                alert(msgErro);
                window.location.reload()
            }
        }

        if (!comErro)
            postJSON('pagamento', sendData).then(resp => {
                const retorno = resp.data

                //console.log(resp.data)

                if (retorno["sucesso"]) {

                    if (retorno["url"]) {
                        this.setState({ ...this.state, loading: false, pago: true, url: retorno["url"] })
                        window.open(retorno["url"], '_blank');
                    } else {
                        this.setState({ ...this.state, loading: false, pago: true, url: "" })
                    }

                } else if (retorno["erro"]) {
                    console.log(retorno)
                    alert(retorno["msg"])
                    this.setState({ ...this.state, loading: false, pago: false, url: "" })
                }

            })
        else
            this.setState({ ...this.state, loading: false, pago: false, url: "" })
    }

    changeUF(e) {
        this.setState({ ...this.state, uf: e.target.value })
    }
    changeNOME(e) {
        this.setState({ ...this.state, nome: e.target.value })
    }
    changeCPF(e) {
        this.setState({ ...this.state, cpf: e.target.value })
    }
    changeTEL(e) {
        this.setState({ ...this.state, tel: e.target.value })
    }
    changeEMAIL(e) {
        this.setState({ ...this.state, email: e.target.value })
    }
    changeNASCIMENTO(e) {
        this.setState({ ...this.state, nascimento: e.target.value })
    }
    changeRUA(e) {
        this.setState({ ...this.state, rua: e.target.value })
    }
    changeNUMERO(e) {
        this.setState({ ...this.state, numero: e.target.value })
    }
    changeBAIRRO(e) {
        this.setState({ ...this.state, bairro: e.target.value })
    }
    changeCEP(e) {
        this.setState({ ...this.state, cep: e.target.value })
    }
    changeCIDADE(e) {
        this.setState({ ...this.state, cidade: e.target.value })
    }
    changeCARTAO(e) {
        this.setState({ ...this.state, cartao: e.target.value })
    }
    changeNUMEROCARTAO(e) {
        this.setState({ ...this.state, numeroCartao: e.target.value })
    }
    changeCVV(e) {
        this.setState({ ...this.state, cvv: e.target.value })
    }
    changeVALIDADE(e) {
        this.setState({ ...this.state, validade: e.target.value })
    }

    handlePGCartao(e) {
        this.setState({ ...this.state, tpPagamento: 1 })
    }
    handlePGBoleto(e) {
        this.setState({ ...this.state, tpPagamento: 2 })
    }
    changeVALOR(e) {
        this.setState({ ...this.state, valor: e.target.value })
    }


    render() {

        return (
            <JanelaPadrao titulo="Doação" loading={this.state.loading} >
                <If test={!this.state.loading && !this.state.pago} >
                    <div className="row" >
                        <div className="col-xs-6 col-sm-6 col-md-6" >
                            <input type="radio" name="tipo" checked={this.state.tpPagamento === 1} onChange={this.handlePGCartao} /> Cartão
                    </div>
                        <div className="col-xs-6 col-sm-6 col-md-6" >
                            <input type="radio" name="tipo" checked={this.state.tpPagamento === 2} onChange={this.handlePGBoleto} /> Boleto
                    </div>
                    </div>
                    <div className="row  font-weight-bold" >

                        <div className="col-xs-8 col-sm-8 col-md-8" >
                            Nome:&nbsp;
                        <input value={this.state.nome} onChange={this.changeNOME} className='form-control' ></input>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            Data Nascimento:&nbsp;
                        <InputMask value={this.state.nascimento} onChange={this.changeNASCIMENTO} className='form-control' mask="99/99/9999" ></InputMask >
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            CPF:&nbsp;
                        <InputMask value={this.state.cpf} onChange={this.changeCPF} className='form-control' mask="999.999.999-99" ></InputMask >
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            Telefone:&nbsp;
                        <InputMask value={this.state.tel} onChange={this.changeTEL} className='form-control' mask="(99)99999-9999" ></InputMask >
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            <text>Email:&nbsp;</text>
                            <input value={this.state.email} onChange={this.changeEMAIL} className='form-control' ></input>
                        </div>

                        <div className="col-xs-8 col-sm-8 col-md-8" >
                            Rua:&nbsp;
                        <input value={this.state.rua} onChange={this.changeRUA} className='form-control' ></input>
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2" >
                            Numero:&nbsp;
                        <input value={this.state.numero} onChange={this.changeNUMERO} className='form-control' ></input>
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2" >
                            Bairro:&nbsp;
                        <input value={this.state.bairro} onChange={this.changeBAIRRO} className='form-control' ></input>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            CEP:&nbsp;
                        <InputMask value={this.state.cep} onChange={this.changeCEP} className='form-control' mask="99999-999"  ></InputMask >
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            Cidade:&nbsp;
                        <input value={this.state.cidade} onChange={this.changeCIDADE} className='form-control' ></input>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4" >
                            Estado:&nbsp;
                        <select value={this.state.uf} onChange={this.changeUF} className='form-control' >
                                <option key="AC" value="AC" >AC</option>
                                <option key="AL" value="AL" >AL</option>
                                <option key="AM" value="AM" >AM</option>
                                <option key="AP" value="AP" >AP</option>
                                <option key="BA" value="BA" >BA</option>
                                <option key="CE" value="CE" >CE</option>
                                <option key="DF" value="DF" >DF</option>
                                <option key="ES" value="ES" >ES</option>
                                <option key="GO" value="GO" >GO</option>
                                <option key="MA" value="MA" >MA</option>
                                <option key="MG" value="MG" >MG</option>
                                <option key="MS" value="MS" >MS</option>
                                <option key="MT" value="MT" >MT</option>
                                <option key="PA" value="PA" >PA</option>
                                <option key="PB" value="PB" >PB</option>
                                <option key="PE" value="PE" >PE</option>
                                <option key="PI" value="PI" >PI</option>
                                <option key="PR" value="PR" >PR</option>
                                <option key="RJ" value="RJ" >RJ</option>
                                <option key="RN" value="RN" >RN</option>
                                <option key="RO" value="RO" >RO</option>
                                <option key="RR" value="RR" >RR</option>
                                <option key="RS" value="RS" >RS</option>
                                <option key="SC" value="SC" >SC</option>
                                <option key="SE" value="SE" >SE</option>
                                <option key="SP" value="SP" >SP</option>
                                <option key="TO" value="TO" >TO</option>
                            </select>
                        </div>

                    </div>

                    <If test={this.state.tpPagamento === 1} >
                        <div className="row  font-weight-bold" >
                            <div className="col-xs-2 col-sm-2 col-md-2" >
                                Cartao:&nbsp;
                        <select value={this.state.cartao} onChange={this.changeCARTAO} className='form-control' >
                                    <option key="visa" value="visa" >Visa</option>
                                    <option key="mastercard" value="mastercard" >Mastercard</option>
                                    <option key="diners" value="diners" >Diners</option>
                                    <option key="amex" value="amex" >Amex</option>
                                    <option key="elo" value="elo" >Elo</option>
                                    <option key="hipercard" value="hipercard" >Hipercard</option>
                                </select>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6" >
                                Cartão:&nbsp;
                        <InputMask value={this.state.numeroCartao} onChange={this.changeNUMEROCARTAO} className='form-control' mask="9999999999999999"  ></InputMask >
                            </div>
                            <div className="col-xs-2 col-sm-2 col-md-2" >
                                CVV:&nbsp;
                        <InputMask value={this.state.cvv} onChange={this.changeCVV} className='form-control' mask="999"  ></InputMask >
                            </div>
                            <div className="col-xs-2 col-sm-2 col-md-2" >
                                Validade:&nbsp;
                        <InputMask value={this.state.validade} onChange={this.changeVALIDADE} className='form-control' mask="99/9999"  ></InputMask >
                            </div>
                        </div>
                    </If >

                    <div className="row  font-weight-bold" >
                        <div className="col-xs-2 col-sm-2 col-md-2" >
                            <text>Valor da Doção:&nbsp;</text>
                            <input value={this.state.valor} onChange={this.changeVALOR} className='form-control' ></input>
                        </div>


                        <div className="col-xs-10 col-sm-10 col-md-10" style={{ marginTop: 20 }} >
                            <button className='btn btn-primary' onClick={() => this.gerar()} >
                                <i className='fa fa-shopping-cart'></i>
                            &nbsp;Conformar Doação
                        </button>
                        </div>
                    </div>
                </If>

                <If test={this.state.pago} >
                    <div className="col-xs-12 col-sm-12 col-md-12" >
                        <h1>Obrigado pela sua doação!</h1>
                    </div>
                    <If test={this.state.url !== ""} >
                        <div className="col-xs-12 col-sm-12 col-md-12" >
                            <a href={this.state.url} target="_blank" rel="noopener noreferrer" >Link para visualizar o boleto!</a>
                        </div>
                    </If>
                </If>
                <Row className="justify-content-center">
                    <Card >
                        <CardBody className="text-center">
                            <div className="hsm-logo-login container">
                                <img src={logo} alt="Imagem do HSM" />
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </JanelaPadrao >
        );
    }
}

export default Dashboard