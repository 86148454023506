import React, { Component } from 'react';
import JanelaPadrao from '../../componentes/JanelaPadrao'
//import dados from './dados.json'
import If from '../../util/if'
import axios from 'axios'

class Dashboard extends Component {

    constructor(props) {
        super(props)

        this.state = { original: [], atual: [], lista: [] }
        //this.state = { original: dados["lista"], atual: dados["lista"], lista: [] }
        this.mudar = this.mudar.bind(this)
        this.voltar = this.voltar.bind(this)


        axios.get("https://web.saomarcos.org.br/dados.json").then(response => response.data)
            .then((dados) => {
                this.setState({ ...this.state, original: dados["lista"], atual: dados["lista"] })
            })
    }

    mudar(item) {
        var aux = this.state.lista
        aux.push(this.state.atual)

        this.setState({ ...this.state, atual: item, lista: aux })
    }

    voltar() {

        var aux = this.state.lista
        var novoAtual = this.state.lista[this.state.lista.length - 1]
        aux.pop()
        this.setState({ ...this.state, atual: novoAtual, lista: aux })
    }

    renderRows() {

        return this.state.atual.map((item, indice) => (
            <div key={indice} style={{ marginTop: 10 }} >
                <If test={item["itens"] && item["itens"].length > 0}  >
                    <input type="button" className="btn btn-danger btn-lg btn-block" value={item["nome"]} onClick={() => this.mudar(item["itens"])} />
                </If>
                <If test={!(item["itens"] && item["itens"].length > 0)} >
                    <span dangerouslySetInnerHTML={{ __html: item["nome"] }} />
                    <br />
                </If>
            </div>
        ))
    }


    render() {

        return (
            <JanelaPadrao titulo="TRIAGEM VIRTUAL" >

                <div className="row  font-weight-bold" >
                    <div className="col-xs-12 col-sm-12 col-md-12 " >
                        <If test={this.state.atual && this.state.atual.length > 0} >
                            {this.renderRows()}
                        </If>
                    </div>
                    <If test={this.state.lista && this.state.lista.length > 0} >
                        <div className="col-xs-12 col-sm-12 col-md-12 " style={{ marginTop: 50 }} >
                            <input type="button" className="btn btn-danger btn-lg btn-block" value="Voltar" onClick={() => this.voltar()} />
                        </div>
                    </If>
                </div>

            </JanelaPadrao>
        );
    }
}

export default Dashboard