import React, { Component } from 'react';
import { postJSON } from '../../util/metodos'
import If from '../../util/if'
import JanelaPadrao from '../../componentes/JanelaPadrao'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        const idLeito = this.props.match.params.id

        this.state = { leito: {}, idLeito: idLeito, msg: "", op: "1", loading: false }
        this.handleMSG = this.handleMSG.bind(this)
        this.handleOP = this.handleOP.bind(this)
        this.pegaPaciente()
    }

    pegaPaciente() {
        let sendData = { "leito": this.state.idLeito }

        postJSON('pacienteinternado', sendData).then(resp => {
            if ((resp.data['pacienteinternado'] || []).length > 0)
                this.setState({ ...this.state, leito: resp.data['pacienteinternado'][0], loading: false })
        })
    }

    handleMSG(e) {
        this.setState({ ...this.state, msg: e.target.value })
    }

    handleOP(e) {
        this.setState({ ...this.state, op: e.target.value })
    }

    enviar() {
        alert(this.state.op)
    }

    render() {

        return (
            <JanelaPadrao titulo="Leitos - Chamados" loading={this.state.loading} >
                <If test={(this.state.leito.leito || "") !== ""} >
                    <div className="row  font-weight-bold" >
                        <div className="col-xs-6 col-sm-6 col-md-6 vermelho" >
                            Leito: {this.state.idLeito}
                        </div>
                        
                        <div className="col-xs-6 col-sm-6 col-md-6 vermelho" >
                            Paciente: {this.state.leito.paciente}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginTop: 10 }} >
                            Selecione a Empresa
                        <br />
                            <select className='form-control' value={this.state.op} onChange={this.handleOP.bind(this)} >
                                <option key="1" value="1" >Ouvidoria</option>
                                <option key="2" value="2" >Solicitação de Enxoval</option>
                            </select>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginTop: 10 }} >
                            Mensagem
                        <br />
                            <textarea value={this.state.msg} onChange={this.handleMSG} style={{ width: '100%', height: "100px" }} ></textarea>
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-1" >
                            <button className='btn btn-primary' onClick={() => this.enviar()} style={{ width: '100%' }}>
                                Enviar
                        </button>
                        </div>
                    </div>
                </If>
                <If test={(this.state.leito.leito || "") === ""} >
                    <h1>Leito esta vago!</h1>
                </If>
            </JanelaPadrao>
        );
    }
}

export default Dashboard