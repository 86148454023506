import React, { Component } from 'react';
import If from '../../util/if'
import JanelaPadrao from '../../componentes/JanelaPadrao'
import { postJSON } from '../../util/metodos'
import Moment from 'moment'

class Medicamento extends Component {
    constructor(props) {
        super(props)

        const tipo = this.props.match.params.tipo || ""
        const id = this.props.match.params.id || ""

        this.state = { loading: false, erro: false, id: id, urlPath: tipo + "/" + id, tipo: tipo, dispensar: false, texto: "", venda: "" }

        this.dispensar = this.dispensar.bind(this)
        this.dispensarConfirmar = this.dispensarConfirmar.bind(this)
        this.changeNOME = this.changeNOME.bind(this)

        if (id !== "") {
            const send = { descricao: tipo + "/" + id }

            postJSON('receita/qr', send).then(resp => {
                if (resp !== undefined) {
                    console.log(resp.data)
                    this.setState({ ...this.state, loading: false, venda: resp.data.vendaFarmacia.replace(new RegExp("\\\\n", "g"),'<br/>') })
                } else {
                    this.setState({ ...this.state, loading: false, erro: true })
                }
            })
        }
    }

    changeNOME(e) {
        this.setState({ ...this.state, texto: e.target.value })
    }

    dispensar() {
        this.setState({ ...this.state, dispensar: true })
    }

    dispensarConfirmar() {
        const venda = this.state.venda + "<br/>" + Moment().format('DD/MM/YYYY HH:mm:ss') + "<br/>" + this.state.texto.replace('\n', '<br/>')

        this.setState({ ...this.state, loading: true })

        const send = { descricao: this.state.texto, usuario: this.state.id }

        postJSON('receita/qr/dispensar', send).then(resp => {
            if (resp !== undefined) {
                this.setState({ ...this.state, loading: false, dispensar: false, texto: "", venda: venda.replace('\n','<br/>') })
            } else {
                this.setState({ ...this.state, loading: false })
            }
        })

    }

    pesquisar() {
        this.setState({ ...this.state, loading: true })
        const send = { descricao: this.state.texto }

        postJSON('receita/qr', send).then(resp => {
            if (resp !== undefined) {
                this.setState({ ...this.state, loading: false, dispensar: false, id: this.state.texto.substring(2), tipo: this.state.texto.substring(0, 1), urlPath: this.state.texto, texto: "", venda: resp.data.vendaFarmacia })
            } else {
                this.setState({ ...this.state, loading: false })
            }
        })

    }

    render() {

        return (
            <JanelaPadrao titulo="Medicamento" loading={this.state.loading} >

                <div className="row  font-weight-bold" >
                    <If test={!this.state.erro} >
                        <If test={this.state.id !== ""} >
                            <If test={!this.state.dispensar} >
                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <button className='btn btn-primary btn-block' onClick={() => window.open("https://www.saomarcos.org.br/web/integra/qr/" + this.state.urlPath, "_blank")} >
                                        <i className='fa fa-print'></i>
                                        <span>&nbsp;Visualizar Receita</span>
                                    </button>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <div dangerouslySetInnerHTML={{ __html: this.state.venda }} />
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <button className='btn btn-primary btn-block' onClick={() => this.dispensar()} >
                                        <i className='fa fa-battery-full'></i>
                                        <span>&nbsp;Farmácia - Dispensar Medicação</span>
                                    </button>
                                </div>
                            </If>

                            <If test={this.state.dispensar} >
                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                        <span>Informe os medicamentos:&nbsp; - Atenção: essa ação não poderá ser desfeita</span>
                                        <textarea value={this.state.texto} onChange={this.changeNOME} className='form-control' style={{ width: '100%', height: "100px" }} />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <button className='btn btn-primary btn-block' onClick={() => this.dispensarConfirmar()} >
                                        <i className='fa fa-battery-full'></i>
                                        <span>&nbsp;Confirmar</span>
                                    </button>
                                </div>
                            </If>
                        </If>

                        <If test={this.state.id === ""} >
                            <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                    <span>Informe o código:&nbsp;</span>
                                    <input value={this.state.texto} onChange={this.changeNOME} className='form-control' />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                <button className='btn btn-primary btn-block' onClick={() => this.pesquisar()} >
                                    <i className='fa fa-battery-full'></i>
                                    <span>&nbsp;Pesquisar</span>
                                </button>
                            </div>
                        </If>
                    </If>
                    <If test={this.state.erro} >
                        <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                            <div className="col-xs-12 col-sm-12 col-md-12" style={{ margin: '4px' }} >
                                <span>Receita inválida ou não encontrada!</span>
                            </div>
                        </div>
                    </If>
                </div>
            </JanelaPadrao >
        );
    }
}

export default Medicamento