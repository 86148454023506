import axios from 'axios'
import Moment from 'moment'

const localStorageKey = '_hsmapp_user_'

const baseUrl = 'https://web.saomarcos.org.br/web/integra/'
//const baseUrl = 'https://ti-02.saomarcos.org.br/web/integra/'
//const baseUrl = 'https://chatbot.saomarcos.org.br:8443/webteste/'
// const baseUrl = 'http://localhost/web/integra/'

var token = ""
var refreshToken = ""
var expires_in = Moment().add(1, 'days')

const TIMEOUT = 60000

try {
  token = getLocalStorage().token || ""
  refreshToken = getLocalStorage().refresh_token || ""
  expires_in = getLocalStorage().expires_in || Moment().add(1, 'days')
} catch (error) {
  token = ""
  refreshToken = ""
  expires_in = Moment().add(1, 'days')
}

export function getJSON(url) {
  url = baseUrl + url
  const conf = {
    method: 'get',
    url: url,
    responseType: 'json',
    timeout: TIMEOUT
  }
  return callAxios(conf);
}

export function postJSON(url, param) {
  url = baseUrl + url
  var conf = {}
  if (param !== undefined && param !== null)
    conf = {
      method: 'post',
      url: url,
      data: param,
      timeout: TIMEOUT
    }
  else
    conf = {
      method: 'post',
      url: url,
      timeout: TIMEOUT
    }

  return callAxios(conf);
}

export function postText(url, param) {
  url = baseUrl + url
  var conf = {}
  if (param !== undefined && param !== null)
    conf = {
      method: 'post',
      url: url,
      data: param,
      timeout: TIMEOUT
    }
  else
    conf = {
      method: 'post',
      url: url,
      timeout: TIMEOUT
    }

  return callAxios(conf);
}

export function putJSON(url, param) {
  url = baseUrl + url
  var conf = {}
  if (param !== undefined && param !== null)
    conf = {
      method: 'put',
      url: url,
      data: param,
      responseType: 'json',
      timeout: TIMEOUT
    }
  else
    conf = {
      method: 'put',
      url: url,
      responseType: 'json',
      timeout: TIMEOUT
    }
  return callAxios(conf);
}

function callAxios(conf) {

  if (refreshToken !== "" && Moment().isAfter(expires_in)) {
    axios.defaults.headers.common['authorization'] = refreshToken
    return axios.post(baseUrl + "login/refresh")
      .then((resp) => {

        token = resp.data.token
        expires_in = Moment().add(parseInt(resp.data.expires_in, 10), 'seconds')

        var local = getLocalStorage()
        local.token = token
        local.expires_in = expires_in
        setLocalStorageJSON(JSON.stringify(local))

        axios.defaults.headers.common['authorization'] = resp.data.token
        return axios(conf).then()
      })
      .catch((error) => {
        try {
          if (error.response.status === 409) {
            alert(error.response.data.titulo)
          } else if (error.response.status === 500 && (error.response.data.erro || "") !== "") {
            alert(error.response.data.erro)
          } else
            if (!(error.response.status === 401 || error.response.status === 403)) {
              alert(error.response.data)
            } else {
              removeLocalStorage()
              //alert(error.response.data)
              window.location.reload()
            }
        } catch (errors) {
          alert("Erro identificado pelo servidor!")
        }
      });

  } else {
    axios.defaults.headers.common['authorization'] = token
    return axios(conf).then()
      .catch((error) => {
        try {
          if (error.response.status === 409) {
            alert(error.response.data.titulo)
          } else if (error.response.status === 500 && (error.response.data.erro || "") !== "") {
            alert(error.response.data.erro)
          } else
            if (!(error.response.status === 401 || error.response.status === 403)) {
              alert(error.response.data)
            } else {
              removeLocalStorage()
              //alert(error.response.data)
              window.location.reload()
            }

        } catch (errors) {
          alert("Erro identificado pelo servidor!")
        }
      });
  }
}

export function getJSONExterno(url, param) {
  url = 'https://cors-anywhere.herokuapp.com/' + url

  return axios.post(url, param).then()
}

export function getLocalStorage() {
  if (localStorage.getItem(localStorageKey) != null)
    return JSON.parse(localStorage.getItem(localStorageKey))
  else
    return null
}

export function getLocalStorageParam(parm) {
  if (localStorage.getItem(localStorageKey) != null)
    return JSON.parse(localStorage.getItem(localStorageKey))[parm]
  else
    return null
}

export function setLocalStorageJSON(json) {
  localStorage.setItem(localStorageKey, json)
  const aux = JSON.parse(json)
  token = aux["token"]
  refreshToken = aux["refresh_token"]
  expires_in = aux["expires_in"]
}

export function removeLocalStorage() {
  localStorage.removeItem(localStorageKey)

  token = ""
  refreshToken = ""
  expires_in = Moment().add(1, 'days')
}
