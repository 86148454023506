import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loading from './loading'

export default props => (
    <Card>
        <CardHeader style={{ display: 'flex',flexDirection:'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div><i className="fa fa-align-justify"></i> {props.titulo}</div>
            <Loading loading={props.loading} />
        </CardHeader>
        <CardBody>            
            {props.children}
        </CardBody>
    </Card>
)