import React, { Component } from 'react';

class DefaultFooter extends Component {
  render() {
    return (
      <React.Fragment>
        <span>&copy; 2024 Hospital São Marcos.</span>
      </React.Fragment>
    );
  }
}
export default DefaultFooter;
